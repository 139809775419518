import {PipeType} from '../../enums/pipe-type.enum';
import {Collections} from '../../interfaces/collections';
import {Module} from '../../interfaces/module.interface';
import JSX from '../../jsx.compiler';
import {CREATED_ON} from '../shared/created-on';
import {YES_NO_PIPE} from '../shared/yes-no-pipe';

export const EMAIL_CAMPAIGN_EMAILS_MODULE: Module = {
	id: Collections.CampaignEmailsSub(),
	name: 'Campaign Emails',
	layout: {
		sort: CREATED_ON.sort,
		filterModule: {
			schema: {
				properties: {
					email: {type: 'string'},
					sent: {type: 'boolean', default: null},
					opened: {type: 'boolean', default: null},
					unsubscribed: {type: 'boolean', default: null}
				}
			},
			definitions: {
				sent: {
					component: {
						type: 'select',
						configuration: {
							dataSet: [
								{name: 'Any', value: null},
								{name: 'Sent', value: true},
								{name: 'Not Sent', value: false}
							]
						}
					}
				},
				opened: {
					component: {
						type: 'select',
						configuration: {
							dataSet: [
								{name: 'Any', value: null},
								{name: 'Opened', value: true},
								{name: 'Not Opened', value: false}
							]
						}
					}
				},
				unsubscribed: {
					component: {
						type: 'select',
						configuration: {
							dataSet: [
								{name: 'Any', value: null},
								{name: 'Unsubscribed', value: true},
								{name: 'Not Unsubscribed', value: false}
							]
						}
					}
				},
			},
			clearFilters: {}
		},
		instance: {
			segments: [
				{
					fields: [
						'/email',
						'/sent',
						'/opened',
						'/unsubscribed',
						'/context',
						'/html'
					]
				}
			],
			actions: [
				{
					value: it => JSX(<jms-e-link icon="file_open" relative={true} link={it.id + '/opens'}>View Opens</jms-e-link>)
				},
			]
		},
		table: {
			tableColumns: [
				{key: '/id', label: 'ID', disabled: true},
				CREATED_ON.column(),
				{key: '/email', label: 'EMAIL'},
				{key: '/sent', label: 'Sent', ...YES_NO_PIPE},
				{
					key: '/sentOn',
					label: 'Sent On',
					pipe: [PipeType.Date],
					pipeArguments: {
						0: 'medium'
					}
				},
				{key: '/opened', label: 'Opened', ...YES_NO_PIPE},
				{
					key: '/openedOn',
					label: 'Opened On',
					pipe: [PipeType.Date],
					pipeArguments: {
						0: 'medium'
					}
				},
				{key: '/unsubscribed', label: 'Unsubscribed', ...YES_NO_PIPE},
				{
					key: '/unsubscribedOn',
					label: 'Unsubscribed On',
					pipe: [PipeType.Date],
					pipeArguments: {
						0: 'medium'
					}
				}
			]
		}
	},
	schema: {
		properties: {
			id: {type: 'string'},
			email: {type: 'string'},
			opened: {type: 'boolean', default: false},
			openedOn: {type: 'number'},
			sent: {type: 'boolean', default: false},
			sentOn: {type: 'number'},
			unsubscribed: {type: 'boolean', default: false},
			unsubscribedOn: {type: 'number'},
			html: {type: 'string'},
			context: {type: 'string', default: '{}'},
			...CREATED_ON.property
		}
	},
	definitions: {
		email: {
			component: {
				type: 'input',
				configuration: {
					type: 'email'
				}
			}
		},
		html: {
			component: {
				// @ts-ignore
				type: 'monaco',
				configuration: {
					// @ts-ignore
					options: {
						language: 'html'
					}
				}
			}
		},
		context: {
			component: {
				// @ts-ignore
				type: 'monaco',
				configuration: {
					// @ts-ignore
					options: {
						language: 'json'
					}
				}
			}
		},
		...CREATED_ON.definition()
	}
};
