import {Collections} from '../../interfaces/collections';
import {Module} from '../../interfaces/module.interface';
import {CREATED_ON} from '../shared/created-on';

export const EMAIL_CAMPAIGN_EMAIL_OPENS_MODULE: Module = {
	id: Collections.CampaignEmailOpensSub(),
	name: 'Campaign Email Opens',
	layout: {
		sort: CREATED_ON.sort,
		table: {
			tableColumns: [
				{key: '/id', label: 'ID', disabled: true},
				CREATED_ON.column(),
				{key: '/url', label: 'URL'},
				{key: '/ip', label: 'IP'},
				{key: '/ips', label: 'IPS'}
			]
		}
	},
	schema: {
		properties: {
			id: {type: 'string'},
			url: {type: 'string'},
			ip: {type: 'string'},
			ips: {type: 'array'},
			...CREATED_ON.property
		}
	},
	definitions: {
		...CREATED_ON.definition()
	} as any
};
