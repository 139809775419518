import {PipeType} from '../../enums/pipe-type.enum';
import {Collections} from '../../interfaces/collections';
import {Module} from '../../interfaces/module.interface';
import {CREATED_ON} from '../shared/created-on';

export const UNSUBSCRIBED_MODULE: Module = {
	id: Collections.Unsubscribed,
	name: 'Unsubscribed',
	layout: {
		editTitleKey: 'email',
		sort: CREATED_ON.sort,
		filterModule: {
			clearFilters: [],
			segments: [{type: 'empty', fields: ['/email']}],
			schema: {
				properties: {
					email: {type: 'string'}
				}
			},
			definitions: {
				email: {
					component: {
						type: 'input',
						configuration: {
							type: 'email'
						}
					}
				}
			}
		},
		instance: {
			segments: [
				{
					fields: [
						'/email',
						'/c'
					]
				}
			]
		},
		table: {
			tableColumns: [
				CREATED_ON.column(),
				{key: '/email', label: 'EMAIL'},
				{
					key: '/c',
					label: 'C',
					pipe: [PipeType.Custom],
					pipeArguments: {
						0: v => v ? atob(v) : '-'
					}
				}
			]
		}
	},
	schema: {
		properties: {
			id: {type: 'string'},
			email: {type: 'string'},
			c: {type: 'string'},
			...CREATED_ON.property
		}
	},
	definitions: {
		email: {
			component: {
				type: 'input',
				configuration: {
					type: 'email'
				}
			}
		},
		...CREATED_ON.definition()
	}
};
