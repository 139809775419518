<mat-action-list class="cursor">
  <button mat-list-item *ngFor="let type of types | keyvalue" (click)="selectType(type.value)">
    {{type.key}}
  </button>
</mat-action-list>

<ng-template #options>
  <h4 mat-dialog-title>{{'EXPORT_OPTIONS' | transloco}}</h4>

  <form [formGroup]="form">
    <mat-dialog-content>
      <div class="m-b-s" *ngIf="data.filterModule && data.filterValue">
        <mat-checkbox formControlName="useFilters">{{'USE_FILTERS' | transloco}}</mat-checkbox>
      </div>

      <mat-form-field class="w-full">
        <mat-label>{{'ROWS_TO_SKIP' | transloco}}</mat-label>
        <input type="number" matInput formControlName="skip">
      </mat-form-field>

      <mat-form-field class="w-full">
        <mat-label>{{'LIMIT' | transloco}}</mat-label>
        <input type="number" matInput formControlName="limit">
      </mat-form-field>

      <div class="m-b-s">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>{{'ADJUST_COLUMNS' | transloco}}</mat-panel-title>
            </mat-expansion-panel-header>

            <jms-column-organization #col [tableColumns]="data.columns"></jms-column-organization>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button mat-flat-button mat-dialog-close>{{'CANCEL' | transloco}}</button>
      <button type="submit" mat-flat-button color="primary" [jpLoadClick]="export(col)">{{'EXPORT' | transloco}}</button>
    </mat-dialog-actions>
  </form>
</ng-template>
