import {Module} from '../../interfaces/module.interface';
import {Collections} from '../../interfaces/collections';
import {CREATED_ON} from '../shared/created-on';
import JSX from '../../jsx.compiler';

export const EMAIL_CAMPAIGNS_MODULE: Module = {
	id: Collections.EmailCampaigns,
	name: 'Email Campaigns',
	layout: {
		editTitleKey: 'name',
		sort: CREATED_ON.sort,
		instance: {
			segments: [
				{
					fields: [
						'/name',
						'/description',
						'/subject',
						'/emailContent'
					]
				}
			],
			actions: [
				{
          value: it => JSX(<jms-e-link icon="email" link={'/m/email-campaigns/' + it.id + '/emails'}>View Emails</jms-e-link>)
        },
				{
          value: it => JSX(<jms-e-link icon="share" link={'/m/email-campaigns/' + it.id + '/sends'}>View Sends</jms-e-link>)
        },
				{
					value: it => JSX(<jms-e-send-campaign-emails id={it.id} />)
				}
			]
		},
		table: {
			tableColumns: [
				CREATED_ON.column(),
        {key: '/name', label: 'NAME'},
        {key: '/description', label: 'DESCRIPTION'},
				{key: '/emails', label: 'Emails'},
				{key: '/sent', label: 'Sent Emails'},
				{key: '/opened', label: 'Opened Emails'},
				{key: '/unsubscribed', label: 'Unsubscribed Emails'}
			]
		}
	},
	schema: {
		properties: {
			id: {type: 'string'},
			name: {type: 'string'},
			description: {type: 'string'},
			emailContent: {type: 'string'},
			firstSentOn: {type: 'number'},
			emails: {type: 'number'},
			sent: {type: 'number'},
			opened: {type: 'number'},
			unsbuscribed: {type: 'number'},
			subject: {type: 'string'},
			...CREATED_ON.property
		},
		reqired: [
			'name',
			'subject',
			'emailContent'
		]
	},
	definitions: {
		description: {
			component: {
				type: 'textarea'
			}
		},
		emailContent: {
			component: {
				// @ts-ignore
				type: 'monaco',
				configuration: {
					// @ts-ignore
					options: {
						language: 'html'
					}
				}
			}
		},
		...CREATED_ON.definition()
	},
	metadata: {
		docIdPrefix: 'ec',
		subCollections: [
			{name: Collections.Emails}
		]
	}
}