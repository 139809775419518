export class Collections {
  static Users = 'users';
  static Roles = 'roles';
	static UserInvites = 'user-invites';

	static AutomaticEmails = 'automatic-emails';
	static SentEmails = 'sent-emails';
	static EmailCampaigns = 'email-campaigns';
	static Unsubscribed = 'unsubscribed';
	static NewsletterSubscriptions = 'newsletter-subscriptions';

	/**
	 * Website
	 */
	static Posts = 'posts'

	/**
	 * Subcollections
	 */
	static History = 'history';
	static Notes = 'notes';
	static Emails = 'emails';
	static Sends = 'sends';
	static Opens = 'opens';

	static HistorySub(collection: string, docId?: string) {
		return `${collection}/${docId || '{docId}'}/${Collections.History}`;
	}

	static get UserHistory() {
		return Collections.HistorySub('users');
	}

	static UserHistorySub(docId: string) {
		return Collections.HistorySub('users', docId);
	}

	static get RoleHistory() {
		return Collections.HistorySub('roles');
	}

	static RoleHistorySub(docId: string) {
		return Collections.HistorySub('roles', docId);
	}

	static CampaignEmailsSub(docId?: string) {
		return `${Collections.EmailCampaigns}/${docId || '{docId}'}/${Collections.Emails}`;
	}

	static CampaignSendsSub(docId?: string) {
		return `${Collections.EmailCampaigns}/${docId || '{docId}'}/${Collections.Sends}`;
	}

	static CampaignEmailOpensSub(docId?: string, subDocId?: string) {
		return `${Collections.EmailCampaigns}/${docId || '{docId}'}/${Collections.Emails}/${Collections.Opens}/${subDocId || '{docId}'}`;
	}
}