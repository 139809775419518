import {FilterMethod} from '../../enums/filter-method.enum';
import {Collections} from '../../interfaces/collections';
import {Module} from '../../interfaces/module.interface';
import {CREATED_ON} from '../shared/created-on';

export const EMAIL_CAMPAIGN_SENDS_MODULE: Module = {
	id: Collections.CampaignSendsSub(),
	name: 'Campaign Sends',
	layout: {
		sort: CREATED_ON.sort,
		filterModule: {
			schema: {
				properties: {
					emails: {type: 'array'},
				}
			},
			definitions: {
				emails: {
					filterMethod: FilterMethod.ArrayContainsAny,
					component: {
						type: 'chips'
					}
				}
			},
			clearFilters: {}
		},
		instance: {
			segments: [
				{
					fields: [
						'/emails'
					]
				}
			]
		},
		table: {
			hideDelete: true,
			hideCheckbox: true,
			tableColumns: [
				CREATED_ON.column(),
				{key: '/emails', label: 'Emails'}
			]
		}
	},
	schema: {
		properties: {
			id: {type: 'string'},
			emails: {type: 'array'},
			...CREATED_ON.property
		}
	},
	definitions: {
		emails: {
			component: {
				type: 'chips'
			}
		},
		...CREATED_ON.definition()
	}
};
