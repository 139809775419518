/**
 * Shared public environment config
 */
export const ENV_CONFIG = {
  firebase: {
    "projectId": "jaspero-web-security",
    "appId": "1:662285124226:web:672f65844d3bf9ba456a9b",
    "storageBucket": "jaspero-web-security.appspot.com",
    "locationId": "europe-west",
    "apiKey": "AIzaSyAAUixSwA39TgBPrXofc08bxt-ps-5Pezc",
    "authDomain": "jaspero-web-security.firebaseapp.com",
    "messagingSenderId": "662285124226",
    "measurementId": "G-NJ8WBFQQR9"
  }
};
