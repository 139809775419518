import {ROLES_MODULE} from './roles.module';
import {USER_HISTORY_MODULE} from './user-history.module';
import {USER_INVITES_MODULE} from './user-invites.module';
import {USERS_MODULE} from './users.module';
import {STORAGE_MODULE} from './storage.module';
import {AUTOMATIC_EMAILS_MODULE} from './emails/automatic-emails.module';
import {SENT_EMAILS_MODULE} from './emails/sent-emails.module';
import {ROLE_HISTORY_MODULE} from './role-history.module';
import {POSTS_MODULE} from './website/posts.module';
import {EMAIL_CAMPAIGNS_MODULE} from './emails/email-campaigns.module';
import {EMAIL_CAMPAIGN_EMAILS_MODULE} from './emails/email-campaign-emails.module';
import {UNSUBSCRIBED_MODULE} from './emails/unsubscribed.module';
import {EMAIL_CAMPAIGN_SENDS_MODULE} from './emails/email-campaign-sends.module';
import {NEWSLETTER_SUBSCRIPTIONS_MODULE} from './emails/newsletter-subscriptions';
import {EMAIL_CAMPAIGN_EMAIL_OPENS_MODULE} from './emails/email-campaign-email-opens.module';

/**
 * Schemas for all of the modules
 */
export const MODULES = [
  USERS_MODULE,
  ROLES_MODULE,
  USER_INVITES_MODULE,
  USER_HISTORY_MODULE,
  ROLE_HISTORY_MODULE,
  STORAGE_MODULE,

  AUTOMATIC_EMAILS_MODULE,
  SENT_EMAILS_MODULE,
  EMAIL_CAMPAIGNS_MODULE,
  EMAIL_CAMPAIGN_EMAILS_MODULE,
  UNSUBSCRIBED_MODULE,
  EMAIL_CAMPAIGN_SENDS_MODULE,
  NEWSLETTER_SUBSCRIPTIONS_MODULE,
  EMAIL_CAMPAIGN_EMAIL_OPENS_MODULE,

  /**
   * Website
   */
  POSTS_MODULE
];
