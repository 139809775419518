import {Collections} from '../../interfaces/collections';
import {Module} from '../../interfaces/module.interface';
import {CREATED_ON} from '../shared/created-on';

export const NEWSLETTER_SUBSCRIPTIONS_MODULE: Module = {
	id: Collections.NewsletterSubscriptions,
	name: 'Newsletter Subscriptions',
	layout: {
		editTitleKey: 'email',
		sort: CREATED_ON.sort,
		instance: {
			segments: [
				{
					fields: [
						'/email'
					]
				}
			]
		},
		table: {
			tableColumns: [
				CREATED_ON.column(),
				{key: '/email', label: 'EMAIL'}
			]
		}
	},
	schema: {
		properties: {
			id: {type: 'string'},
			email: {type: 'string'},
			...CREATED_ON.property
		},
		reqired: [
			'email'
		]
	},
	definitions: {
		email: {
			component: {
				type: 'input',
				configuration: {
					type: 'email'
				}
			}
		},
		...CREATED_ON.definition()
	}
}